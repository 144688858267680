import React from 'react';
import Select from 'react-select';
import {CSSTransition} from "react-transition-group";
import './style.css'
import logo from './images/JinjaNinja_Logo-01.png';
import insertTextAtCursor from 'insert-text-at-cursor';


class MessageBox extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            map_keys: [],
            attr_map: [],
            activeMenu: 'main',
            message_body: '',
        };
        this.handleMapKeyChange = this.handleMapKeyChange.bind(this);
        this.handleMapValueChange = this.handleMapValueChange.bind(this);
        this.handleMessageBodyChange = this.handleMessageBodyChange.bind(this);
    }

    handleMessageBodyChange(event) {
        this.setState({
            message_body: event.target.value,
        })
    }

    handleMapKeyChange(optionValue) {
        console.log(optionValue)
        this.setState({
            activeMenu: 'json_data',
        })
        this.fetchAttrMap(optionValue);
    }

    handleMapValueChange(optionValue) {
        const el = document.getElementById('message_body');
        insertTextAtCursor(el, optionValue);
    }

    componentDidMount() {
        var targetUrl = 'https://nephotools-dev-backend-tpx3bvf6rq-ue.a.run.app/api/v1/map/list'
        fetch(targetUrl)
            .then(response => response.json())
            .then(data =>
                this.setState({
                    map_keys: data.keys
                })
            );
    }

    fetchAttrMap = (optionValue) => {
        var target = "https://nephotools-dev-backend-tpx3bvf6rq-ue.a.run.app/api/v1/map/get?id="
        fetch(target+optionValue)
            .then(response => response.json())
            .then(data =>
                this.setState({
                    attr_map: data.attributes
                })
            );
    };

    render() {
        return (
            <div className={"messagebox"}>
                <header>
                    <img src={logo} alt="Logo" class="jn-logo"></img>
                </header>
                <main>
                    <div className={"top"}>
                        <div className={"split left"}>
                            <CSSTransition unmountOnExit in={this.state.activeMenu === "main"} timeout={100}>
                                <Select menuIsOpen
                                        classNamePrefix={"main"} className={"main"}
                                        onChange={opt => {
                                            this.handleMapKeyChange(opt.label)
                                        }}
                                        options={
                                            this.state.map_keys.map((mapkey, index) => ({
                                                label: mapkey,
                                                value: index
                                            }))}/>
                            </CSSTransition>

                            <CSSTransition unmountOnExit in={this.state.activeMenu === "json_data"}
                                           timeout={100}>
                                <div className={"json_data"}>
                                    <button className={"json_data-button"}
                                            onClick={() => {
                                                this.setState({
                                                    activeMenu: "main"
                                                })
                                            }}/>
                                    <Select menuIsOpen
                                            className={"json_data-select"}
                                            onChange={opt => this.handleMapValueChange(opt.value)}
                                            options={this.state.attr_map.map((map) => ({
                                                label: map,
                                                value: '{{ resource.' + map + ' }}'
                                            }))}/>
                                </div>
                            </CSSTransition>
                        </div>
                        <div className={"split right"}>
                         <textarea className={"message_body"} id={"message_body"}
                                   value={this.state.message_body} name={"message_body"}
                                   onChange={this.handleMessageBodyChange}/>
                        </div>
                    </div>
                </main>
            </div>
        )
    }
}

export default MessageBox;
